import * as React from 'react'

import { graphql } from 'gatsby';

import Layout from '../components/Layout'
import Seo from '../components/Seo';

const AgbPage = ({ data }) => {
  return (
    <Layout >
      <Seo
        title="AGB"
        description="Allgemeine Geschäftsbedingungen (AGB) von u1Dent.app"
      />
      <div className="max-w-md mx-auto bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden md:max-w-3xl">
        <div
          className="h-full"
        >
          <div
            className="p-6 mb-2 rounded-xl bg-grey-light dark:bg-grey-dark shadow-md text-text-light dark:text-text-dark"
          >
            <div dangerouslySetInnerHTML={{ __html: data.agb.termsAndConditions }} />
          </div>
        </div>
      </div>
    </Layout >
  )
}

export default AgbPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    agb: iubendaDocument {
      termsAndConditions
    }
  }
`;